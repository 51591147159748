import AboutUsContainer from '../HomeContainer/AboutUsContainer/AboutUsContainer'
import CurationContainer from '../HomeContainer/CurationContainer/CurationContainer'
import AmenitiesContainer from './AmenitiesContainer/AmenitiesContainer'
import HouseDetails from './AmenitiesContainer/HouseDetails/HouseDetails'
import BookContainer from './BookContainer/BookContainer'
import './HouseContainer.css'
import HouseLandingContainer from './HouseLandingContainer/HouseLandingContainer'

function HouseContainer({ id }) {
    return (
        <div className='house-container'>
            <HouseLandingContainer id={id} />
            <HouseDetails id={id} />
            <AmenitiesContainer />
            <BookContainer />
            <CurationContainer showCarouselOnly={true} customTitle='Explore Other Options' />
            <AboutUsContainer />
        </div>
    )
}

export default HouseContainer