import React, { useState } from 'react';
import './AmenityItem.css';

function AmenityItem({ title, bulletPoints, image }) {
    const [isExpanded, setIsExpanded] = useState(false);

    // Maximum visible bullet points before "Read More"
    const maxVisibleBullets = 3;

    const toggleExpand = () => {
        setIsExpanded((prevState) => !prevState);
    };

    // Determine the points to display based on the state
    const visibleBulletPoints = isExpanded ? bulletPoints : bulletPoints.slice(0, maxVisibleBullets);

    return (
        <div className="amenity-item">
            {image && <img src={image} alt={title} className="amenity-item-image" />}
            <div className="amenity-item-title">{title}</div>
            <ul className="amenity-item-bullets">
                {visibleBulletPoints.map((bullet, index) => (
                    <li key={index} className="amenity-item-bullet">{bullet}</li>
                ))}
            </ul>
            {bulletPoints.length > maxVisibleBullets && (
                <button onClick={toggleExpand} className="amenity-item-toggle">
                    {isExpanded ? 'Read Less' : 'Read More'}
                </button>
            )}
        </div>
    );
}

export default AmenityItem;
