import React, { useState } from 'react';
import './FAQContainer.css';

function FAQContainer() {
    const [activeIndex, setActiveIndex] = useState(null);

    const faqData = [
        { question: 'What is your return policy?', answer: 'Our return policy allows returns within 30 days of purchase.' },
        { question: 'How do I track my order?', answer: 'You can track your order through the link provided in the confirmation email.' },
        { question: 'Do you offer customer support?', answer: 'Yes, our customer support is available 24/7 via email and phone.' },
        { question: 'Can I change my order?', answer: 'Orders can be modified within 2 hours of placing the order.' },
    ];

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <div className="faq-container">
            <div className="faq-title">FAQ</div>
            <div className="questions-container">
                {faqData.map((item, index) => (
                    <div
                        key={index}
                        className={`accordion-item ${activeIndex === index ? 'active' : ''}`}
                        onClick={() => toggleAccordion(index)}
                    >
                        <div className={`accordion-question ${activeIndex === index ? 'bold' : ''}`}>{item.question}</div>
                        <div className="accordion-answer">
                            {activeIndex === index ? item.answer : null}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default FAQContainer;
