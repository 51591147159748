import React from 'react';
import './AmenitiesContainer.css';
import AmenityItem from './AmenityItem/AmenityItem';

function AmenitiesContainer() {
    const amenities = [
        {
            title: 'Luxury Pool',
            bulletPoints: [
                'Temperature controlled',
                'Infinity view',
                'Poolside bar',
                'Private cabanas available',
                'Children’s pool section'
            ],
            // image: '/path/to/pool-image.jpg',
        },
        {
            title: 'Spa & Wellness',
            bulletPoints: [
                'Full-body massages',
                'Aromatherapy sessions',
                'Yoga and meditation classes',
                'Sauna and steam rooms',
                'Personalized wellness packages'
            ],
            // image: '/path/to/spa-image.jpg',
        },
        {
            title: 'Gourmet Dining',
            bulletPoints: [
                'World-class chefs',
                'Farm-to-table ingredients',
                'Customizable menu options',
                'Elegant dining ambiance',
                '24/7 room service'
            ],
        },
    ];

    return (
        <div className="amenities-container">
            {amenities.map((amenity, index) => (
                <AmenityItem
                    key={index}
                    title={amenity.title}
                    bulletPoints={amenity.bulletPoints}
                    image={amenity.image}
                />
            ))}
        </div>
    );
}

export default AmenitiesContainer;
