import React from 'react';
import HomeContainer from '../../containers/HomeContainer/HomeContainer';

const Home = () => {
    return (
        <HomeContainer />
    );
};

export default Home;
