import ImageWithText from "../../../components/ImageWithText/ImageWithText";
import Map from '../../../assets/homePage/Map.png'
import House from '../../../assets/homePage/House.png'
import Heart from '../../../assets/homePage/Heart.png'
import Star from '../../../assets/homePage/Star.png'
import './WhyUsContainer.css'

const WhyUsContainer = () => {

    return (
        <div className="why-us-container">
            <p className="why-us-title">Why Us</p>
            <div className='why-us-icons-container'>
                <ImageWithText
                    image={Map}
                    text={<>Carefully curated <br /> selection of luxury <br /> villas</>}
                />
                <ImageWithText
                    image={House}
                    text={<>Hosted 10,000+ <br /> staycations till <br />date</>}
                />
                <ImageWithText
                    image={Heart}
                    text={<>More than 6+ years<br />of experience in <br /> hospitality</>}
                />
                <ImageWithText
                    image={Star}
                    text={<>4.8+<br />Average Rating</>}
                />
            </div>
        </div>
    );
};

export default WhyUsContainer