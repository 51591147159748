import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Home from '../pages/Home/Home';
import Locations from '../pages/Locations/Locations';
import House from '../pages/House/House'; // Import the new component

const AppRoutes = () => {
    return (
        <BrowserRouter>
            <Routes>
                {/* Redirect from / to /home */}
                <Route path="/" element={<Navigate to="/home" replace />} />
                <Route path="/home" element={<Home />} />
                <Route path="/locations" element={<Locations />} />
                <Route path="/house/:id" element={<House />} /> {/* Dynamic Route */}
                {/* Add more public routes here */}
            </Routes>
        </BrowserRouter>
    );
};

export default AppRoutes;
