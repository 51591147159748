import React from 'react';
import './ImageWithText.css';

const ImageWithText = ({ image, text }) => {
    return (
        <div className="image-with-text-container">
            <img src={image} alt="Content" className="image-with-text-image" />
            <p className="image-with-text-text">{text}</p>
        </div>
    );
};

export default ImageWithText;
