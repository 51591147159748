import React from 'react';
import './HomeContainer.css';
import Navigation from '../../components/Navigation/Navigation';
import EleganceContainer from './EleganceContainer/EleganceContainer';
import CurationContainer from './CurationContainer/CurationContainer';
import WhoAreWeContainer from './WhoAreWeContainer/WhoAreWeContainer';
import RevolutionizingContainer from './RevolutionizingContainer/RevolutionizingContainer'
import WhyUsContainer from './WhyUsContainer/WhyUsContainer';
import TestimonialsContainerWrapper from './TestimonialsContainerWrapper/TestimonialsContainerWrapper';
import FAQContainer from './FAQContainer/FAQContainer';
import AboutUsContainer from './AboutUsContainer/AboutUsContainer';


// Define the LandingContainer component
const LandingContainer = () => {
    return (
        <div className="landing-container">
            <Navigation />
            <div className="bottom-content">
                <div className="text-container">
                    <p className="staycation-text">
                        Transform your<br />staycation game
                    </p>
                </div>
                <div className="bottom-line-container">
                    {/* <div className="vertical-line"></div> */}
                </div>
            </div>
        </div>
    );
};

// Define the HomeContainer component
const HomeContainer = () => {
    return (
        <div className="home-container">
            <LandingContainer />
            <EleganceContainer />
            <CurationContainer/>
            <WhoAreWeContainer />
            <RevolutionizingContainer />
            <WhyUsContainer />
            <TestimonialsContainerWrapper />
            <FAQContainer />
            <AboutUsContainer />
            {/* <LocationsContainer /> */}
        </div>
    );
};

export default HomeContainer;
