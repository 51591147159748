import './HouseDetails.css';

import Bed from '../../../../assets/housePage/bed.png';
import Clock from '../../../../assets/housePage/clock.png';
import Guest from '../../../../assets/housePage/guest.png';
import Money from '../../../../assets/housePage/money.png';
import House from '../../../../assets/housePage/House.png';

const imageList = [
    { image: Money, value: 'From 10,000 /-' },
    { image: Bed, value: '4 Beds' },
    { image: Clock, value: '2 PM Clock In' },
    { image: Clock, value: '3 AM Clock Out' },
    { image: Guest, value: 'Up To 10 Guests' },
];

const AmenitiesList = [
    { image: Money, value: 'From 10,000 /-' },
    { image: Bed, value: '4 Beds' },
    { image: Clock, value: '2 PM Clock In' },
    { image: Bed, value: '4 Beds' },
    { image: Clock, value: '2 AM Clock Out' },
    { image: Guest, value: 'Up To 10 Guests' },
    { image: Bed, value: '4 Beds' },
];


const carouselImages = [House, House, House];

const IconItem = ({ image }) => {
    return <img src={image} alt="" className="icon-image" />;
};

function HouseDetails({ id }) {
    return (
        <div className="house-details-container">
            <div className="house-details-title">{id}</div>
            <div className="house-details-desc">
                Experience luxury at Villa Oscar in Lonavla, featuring spacious rooms, a private pool, and stunning views.
                Escape to tranquility and modern comfort in this serene retreat.
            </div>
            <div className="house-details-icon">
                {imageList.map(({ image, value }, index) => (
                    <div className="image-desc-wrapper" key={index}>
                        <IconItem image={image} />
                        <div className="icon-desc">{value}</div>
                    </div>
                ))}
            </div>
            <div className="house-carousel">
                {carouselImages.map((img, index) => (
                    <img src={img} alt={`Carousel ${index}`} className="carousel-image" key={index} />
                ))}
            </div>
            <div className="house-details-title">AMENITIES</div>
            <div className="house-details-icon house-details-amenities">
                {AmenitiesList.map(({ image, value }, index) => (
                    <div className="image-desc-wrapper amenities-wrapper" key={index}>
                        <IconItem image={image} />
                        <div className="icon-desc">{value}</div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default HouseDetails;
