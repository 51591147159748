import React from 'react';
import './Navigation.css';

// Import images from assets
import logo from '../../assets/homePage/home-logo.png';
import menuIcon from '../../assets/homePage/menu-icon.png';

const Navigation = () => {
    return (
        <nav className="navbar">
            <div className="nav-column logo-column">
                <img src={logo} alt="Logo" className="logo" />
            </div>
            <div className="nav-column menu-column">
                <img src={menuIcon} alt="Menu" className="menu-button" />
            </div>
        </nav>
    );
};

export default Navigation;
