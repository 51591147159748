import React, { useState, useRef } from 'react';
import './BookContainer.css';
import calendarIcon from '../../../assets/homePage/calendar.png'; // Replace with your PNG path

function BookContainer() {
    // State for managing dates
    const [checkInDate, setCheckInDate] = useState('2024-01-01'); // Default check-in date
    const [checkOutDate, setCheckOutDate] = useState('2024-01-05'); // Default check-out date

    const checkInRef = useRef(null);
    const checkOutRef = useRef(null);

    // Functions to handle date changes
    const handleCheckInChange = (event) => setCheckInDate(event.target.value);
    const handleCheckOutChange = (event) => setCheckOutDate(event.target.value);

    // Functions to open date picker
    const openDatePicker = (ref) => ref.current.showPicker();

    const [values, setValues] = useState({
        Adults: 0,
        Children: 0,
        Infant: 0,
    }); // Track values for each dropdown

    const [openDropdown, setOpenDropdown] = useState(null); // Track which dropdown is open

    const toggleDropdown = (dropdownName) => {
        setOpenDropdown(openDropdown === dropdownName ? null : dropdownName);
    };

    const handleSelect = (dropdownName, value) => {
        setValues({ ...values, [dropdownName]: value });
        setOpenDropdown(null); // Close the dropdown after selection
    };

    const dropdownOptions = Array.from({ length: 15 }, (_, i) => i); // Generate options 0-14


    return (
        <div className='book-container'>
            <div className='book-title'>Request To Book</div>
            <div className='date-title'>Booking Dates</div>
            <div className='date-picker-container'>
                {/* Check-In */}
                <div className='date-picker'>
                    <label htmlFor='check-in'>Check In</label>
                    <div className='date-input-wrapper'>
                        <span className='date-display'>{checkInDate}</span>
                        <input
                            type='date'
                            id='check-in'
                            name='check-in'
                            ref={checkInRef}
                            value={checkInDate}
                            onChange={handleCheckInChange}
                            className='hidden-date-input'
                        />
                        <img
                            src={calendarIcon}
                            alt='Calendar Icon'
                            className='calendar-icon'
                            onClick={() => openDatePicker(checkInRef)}
                        />
                    </div>
                </div>
                {/* Check-Out */}
                <div className='date-picker'>
                    <label htmlFor='check-out'>Check Out</label>
                    <div className='date-input-wrapper'>
                        <span className='date-display'>{checkOutDate}</span>
                        <input
                            type='date'
                            id='check-out'
                            name='check-out'
                            ref={checkOutRef}
                            value={checkOutDate}
                            onChange={handleCheckOutChange}
                            className='hidden-date-input'
                        />
                        <img
                            src={calendarIcon}
                            alt='Calendar Icon'
                            className='calendar-icon'
                            onClick={() => openDatePicker(checkOutRef)}
                        />
                    </div>
                </div>
            </div>
            <div className='guests-container'>
                <div className='date-title'>Number of Guests</div>
            </div>
            <div className='count-container'>
                {[['Adults', '(Above 5)'], ['Children', '(3-5)'], ['Infant', '(< 3)']].map((label) => (
                    <div className='dropdown-wrapper' key={label}>
                        <div className='dropdown-title'>{label[0]}</div>
                        <div className='desc-placeholder'>{label[1]}</div>
                        <div
                            className={`dropdown ${openDropdown === label[0] ? 'open' : ''}`}
                            onClick={() => toggleDropdown(label[0])}
                        >
                            <div className='dropdown-placeholder'>{values[label[0]]}</div>
                            {openDropdown === label[0] && (
                                <ul className='dropdown-options'>
                                    {dropdownOptions.map((option) => (
                                        <li
                                            key={option}
                                            className='dropdown-option'
                                            onClick={(e) => {
                                                e.stopPropagation(); // Prevent closing dropdown on click
                                                handleSelect(label[0], option); // Set the selected value
                                            }}
                                        >
                                            {option}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    </div>
                ))}
            </div>
            <div className='price-container'>
                <div className='date-title'>PRICE</div>
                <div className='date-title'>Rs 22,000 /-</div>
                <div>onwards</div>
                <button className='book-button'>BOOK NOW</button>
            </div>
            <div className='terms-container'>
                *The villa does not have a confirmed booking for this period but at present someone has a ‘hold’. Charges mentioned above are prices before taxes. Please enter your details on the booking form above and we will get back to you within 24 hours.
            </div>
        </div>
    );
}

export default BookContainer;
