import './TestimonialsContainerWrapper.css';
import ProfilePic from '../../../assets/homePage/profile.jpg'

const TestimonialCard = ({ profilePicture, text, name, rating, date }) => {
    return (
        <div className="testimonial-card">
            <div className="testimonial-header">
                <img src={ProfilePic} alt={name} className="profile-picture" />
                <div className="testimonial-name">{name}</div>
            </div>
            <div className="testimonial-text">{text}</div>
            <div className="testimonial-footer">
                <span className="testimonial-rating">{rating}/5</span>
                <div>|</div>
                <span className="testimonial-date">{"2024.4.2"}</span>
            </div>
        </div>
    );
};
const TestimonialsContainerWrapper = () => {
    const testimonialsData = [
        { text: "My stay at the Grand Vista Hotel was absolutely incredible! From the moment I stepped through the door. I was greeted with warmth and professionalism .", name: "John Doe", rating: 5 },
        { text: "My stay at the Grand Vista Hotel was absolutely incredible! From the moment I stepped through the door. I was greeted with warmth and professionalism .", name: "Chris Lee", rating: 4 },
        { text: "My stay at the Grand Vista Hotel was absolutely incredible! From the moment I stepped through the door. I was greeted with warmth and professionalism .", name: "Alex Smith", rating: 5 },
        { text: "My stay at the Grand Vista Hotel was absolutely incredible! From the moment I stepped through the door. I was greeted with warmth and professionalism .", name: "Patricia Brown", rating: 4 },
        // Add more testimonials as needed
    ];

    // Group testimonials in pairs to show two vertically in each carousel slide
    const pairedTestimonials = [];
    for (let i = 0; i < testimonialsData.length; i += 2) {
        pairedTestimonials.push(testimonialsData.slice(i, i + 2));
    }

    return (
        <div className="testimonials-container-wrapper">
            <p className="testimonials-title">Testimonials</p>
            <div className="testimonials-carousel">
                {pairedTestimonials.map((pair, index) => (
                    <div key={index} className="testimonial-pair">
                        {pair.map((testimonial, idx) => (
                            <TestimonialCard
                                key={idx}
                                text={testimonial.text}
                                name={testimonial.name}
                                rating={testimonial.rating}
                            />
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TestimonialsContainerWrapper;
