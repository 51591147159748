import React from 'react';
import Navigation from '../../components/Navigation/Navigation';
import LocationImage from '../../assets/homePage/home-cover.jpg'
import AboutUsContainer from '../../containers/HomeContainer/AboutUsContainer/AboutUsContainer'
import './LocationsContainer.css';
import { useNavigate } from 'react-router-dom';

function LocationsContainer() {
  const locations = [
    { title: 'Cabin', price: 'Inr 22,000', imageUrl: LocationImage },
    { title: 'Sky', price: 'Inr 30,000', imageUrl: LocationImage },
    { title: 'Mountain', price: 'Inr 18,000', imageUrl: LocationImage },
    { title: 'Beach', price: 'Inr 25,000', imageUrl: LocationImage },
  ];
  const navigate = useNavigate()
  const handleClick = (title) => {
    navigate(`/house/${title}`);
  }

  return (
    <div className="locations-container">
      <Navigation />
      <div className="cards-container">
        <div className='locations-title'>All Locations</div>
        {locations.map((location, index) => (
          <div key={index} className="location-card" style={{ backgroundImage: `url(${location.imageUrl})` }}>
            <div className="overlay">
              <h2 className="card-title">{location.title}</h2>
              <div className="card-footer">
                <p className="price">From {location.price} /-</p>
                <button className="book-button" onClick={() => handleClick(location.title)}>Book Now</button>
              </div>
            </div>
          </div>
        ))}
      </div>
      <AboutUsContainer />
    </div>
  );
}

export default LocationsContainer;
