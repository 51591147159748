import React, { useRef } from 'react';
import CurationSlide from './CurationSlide/CurationSlide';
import './CurationContainer.css';
import CardBg from '../../../assets/homePage/card-bg.png';
import Lonavla from '../../../assets/homePage/lonavla.png';
import Khandala from '../../../assets/homePage/khandala.png';
import Goa from '../../../assets/homePage/goa.jpeg';
import Pawna from '../../../assets/homePage/powna.jpeg';

function CurationContainer({ showCarouselOnly = false, customTitle = 'Curated By Our Experts' }) {
    const scrollRef = useRef();

    // Example data for slides
    const slides = [
        {
            backgroundImage: CardBg,
            title: 'SKY',
            price: 'Rs 1,200,000/- ONWARDS',
            bhk: '4',
            location: 'GOA'
        },
        {
            backgroundImage: CardBg,
            title: 'OSCAR',
            price: 'Rs 1,200,000/- ONWARDS',
            bhk: '3',
            location: 'PAWNA'
        },
        {
            backgroundImage: CardBg,
            title: 'Supreme Bungalow',
            price: 'Rs 1,200,000/- ONWARDS',
            bhk: '2',
            location: 'KATRAJ'
        },
        {
            backgroundImage: CardBg,
            title: 'Urban Apartments',
            price: 'Rs 1,200,000/- ONWARDS',
            bhk: '2',
            location: 'LONAVLA'
        }
    ];

    const locations = [
        { backgroundImage: Lonavla, title: 'Lonavla' },
        { backgroundImage: Khandala, title: 'Khandala' },
        { backgroundImage: Goa, title: 'Goa' },
        { backgroundImage: Pawna, title: 'Pawna' }
    ];

    return (
        <div className="curation-container">
            <h2 className="curation-title">{customTitle}</h2>
            <div className="carousel-wrapper">
                <div className="carousel" ref={scrollRef}>
                    {slides.map((slide, index) => (
                        <CurationSlide
                            key={index}
                            backgroundImage={slide.backgroundImage}
                            title={slide.title}
                            price={slide.price}
                            bhk={slide.bhk}
                            location={slide.location}
                        />
                    ))}
                </div>
            </div>
            {!showCarouselOnly && (
                <>
                    <h2 className="location-title">Locations</h2>
                    <div className="locations-grid">
                        {locations.map((loc, index) => (
                            <div
                                key={index}
                                className="location-item"
                                style={{ backgroundImage: `url(${loc.backgroundImage})` }}
                            >
                                <div className="location-overlay"></div>
                                <h3 className="location-item-title">{loc.title}</h3>
                            </div>
                        ))}
                    </div>
                </>
            )}
        </div>
    );
}

export default CurationContainer;
