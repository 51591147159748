import ContentBlock from '../../../components/ContentBlock/ContentBlock';
import RevolutionizeImage from '../../../assets/homePage/revolutionize.png'
import './RevolutionizingContainer.css'

// Define the RevolutionizingContainer component
const RevolutionizingContainer = () => {
    return (
        <div className="revolutionizing-container">
            <ContentBlock
                image={RevolutionizeImage}
                title="Revolutionizing HOSPITALITY"
                description="Experience unparalleled luxury and comfort with our villa service, where every moment is tailored to exceed your expectations. Nestled amidst breathtaking scenery, our villas offer a sanctuary of serenity, providing the perfect backdrop for your getaway."
            />
        </div>
    );
};

export default RevolutionizingContainer