import './EleganceContainer.css';

function EleganceContainer() {
    return (
        <div className="elegance-container">
            <h2 className="elegance-text">
                Experience the <br />
                elegance of a Perfect <br />
                Staycation
            </h2>
        </div>
    );
}

export default EleganceContainer;