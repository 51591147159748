import React from 'react';
import './CurationSlide.css';

function CurationSlide({ backgroundImage, title, price, bhk, location }) {
    return (
        <div
            className="curation-slide"
            style={{ backgroundImage: `url(${backgroundImage})` }}
        >
            <div className="overlay"></div> {/* Overlay div for semi-transparent black background */}
            <div className="slide-content">
                <h3 className="slide-title">{title}</h3>
                <p className="slide-price">{price}</p>
                <div className="slide-info">
                    <span className="slide-bhk">{bhk} BEDROOMS</span> | 
                    <span className="slide-location">
                        <i className="location-icon">📍</i> {location}
                    </span>
                </div>
            </div>
        </div>
    );
}

export default CurationSlide;
