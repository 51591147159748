import './HouseLandingContainer.css'
import Navigation from '../../../components/Navigation/Navigation'

function HouseLandingContainer({ id }) {
    return (
        <div className='house-landing-container'>
            <Navigation />
            <div className='house-landing-content'>
                <div className='house-landing-title'>
                    {id}
                </div>
                <div className='house-landing-footer'>
                    DESIGNED TO <br />
                    BRING <br />
                    <div className='italics-title'>Luxury</div>
                </div>
            </div>
        </div>
    )
}

export default HouseLandingContainer