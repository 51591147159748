import React from 'react';
import { useParams } from 'react-router-dom';
import HouseContainer from '../../containers/HouseContainer/HouseContainer';

const House = () => {
    const { id } = useParams(); // Access the dynamic parameter from the URL

    return (
        <HouseContainer id={id} />
    );
};

export default House;
