import './AboutUsContainer.css'
import Phone from '../../../assets/homePage/phone.png'
import Logo from '../../../assets/homePage/home-logo.png'

function AboutUsContainer() {
    return (
        <div className='about-us-container'>
            <div className='phone-container'>
                <div className='phone-section'>
                    <img src={Phone} className='phone-image' />
                    <div className='phone-text'>{"Office : (+91) 860 047 9154"}</div>
                </div>
                <div className='phone-section'>
                    <img src={Phone} className='phone-image' />
                    <div className='phone-text'>{"Home : (+91) 860 047 9154"}</div>
                </div>
                <div className='phone-section'>
                    <img src={Phone} className='phone-image' />
                    <div className='phone-text'>{"Email : reservation@holastaycation.com"}</div>
                </div>
            </div>
            <div className='address-container'>
                <div className='pune-title'>PUNE</div>
                <div className='pune-address'>
                    Unit no 5, G-Building, <br />
                    Nav Shanti Niketan Society <br />
                    Sector no 29, Pradhikaran, Pune - 411044 <br />
                </div>
            </div>
            <div className="mail-container">
                <div className="mail-title">Email Us</div>
                <div className="input-wrapper">
                    <input type="email" placeholder="Enter your email" className="email-input" />
                    <button className="submit-button">Submit</button>
                </div>
            </div>
            <div className='social-container'>
                Facebook Whatsapp Instagram
            </div>
            <div className='address-logo-container'>
                <img src={Logo} className='address-logo' />
            </div>
            <div className='rights-container'>
                © 2024 Hola Staycations  •  All Rights Reserved
            </div>
        </div>
    )
}

export default AboutUsContainer