// src/components/ContentBlock/ContentBlock.js
import React from 'react';
import './ContentBlock.css';

const ContentBlock = ({ image, description, title }) => {
    return (
        <div className="content-block">
            <p className="content-title">{title}</p>
            <img src={image} alt="Content" className="content-image" />
            <p className="content-description">
                {description}
            </p>
            <button className="read-more-button">Read More</button>
        </div>
    );
};

export default ContentBlock;
