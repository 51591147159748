import ContentBlock from '../../../components/ContentBlock/ContentBlock';
import './WhoAreWeContainer.css'
import Pawna from '../../../assets/homePage/pawna.jpeg'

// Define the WhoWeAreContainer component
const WhoAreWeContainer = () => {
    return (
        <div className="who-we-are-container">
            <ContentBlock
                image={Pawna}
                title="Who Are We?"
                description="Hola Staycations is a young and dynamic company driven by a simple idea: to create a haven for connection. We believe vacations are all about spending quality time with loved ones, away from the pressures of daily life ."
            />
        </div>
    );
};

export default WhoAreWeContainer